
import './blog.scss';
// import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import { getBestTopicList } from '../../../services/blog/index';
import { BuryingPointFc } from '../../../components/buryingPoint';

const BlogPage = () => {

    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        queryBestTopicListRequest();
    }, [])

    const queryBestTopicListRequest = async () => {
        const req = {
            // channelId: 100135,//线上频道ID
            // channelId: 100,//开发频道ID
            channelId:100135,//频道ID
            page: {
                current: 1,
                pageSize: 50,
                total: 100
            }
        }
        const res = await getBestTopicList(req);
        if (res?.header?.success) {
            setData(res?.data || []);
            setLoading(false);
        }
    }


    return (
        <Layout>
            <Seo title="社区" />
            <div id="blog" className="container" style={{paddingBottom:'1rem'}}>
                {data?.map((item) => [
                    <a
                    key={item?.id}
                    className="row g-0 border mg-3 wh-100 pointer"
                    href={`/tag/blog/detail?id=${item?.id}`}
                    
                    onClick={()=>BuryingPointFc('VO','VO00300100500101',{action:'点击社区文章链接'})}
                    >
                        <div className="col-6 col-md-4 adaptation">
                            <img src={item?.thumbnail} alt={''} style={{ width: '100%', height: '100%' }}></img>
                        </div>
                        <div className="col-sm-6 col-md-8 pd-2">
                            <h5>{item?.title}</h5>
                            <div className="mg-1">{item?.summary}</div>
                        </div>
                    </a>
                ])}
                {loading &&
                    <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                }
                {!loading && data.length == 0 &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <h5>暂无数据</h5>
                    </div>
                }
            </div>
        </Layout>
    )
}



export default BlogPage;